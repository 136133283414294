var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-hover',{class:_vm.poolTier.canInvest ? 'big-primary-border' : '',attrs:{"disable-hover":true,"disableShadow":false}},[_c('v-row',{class:{ 'pa-6': _vm.$vuetify.breakpoint.smAndDown },attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"primary--text font-weight-bold d-flex align-center justify-start",class:{ 'pl-6': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"2","sm":"4"}},[_vm._v(" "+_vm._s(_vm.poolTier.name)+" ")]),_c('v-col',{class:{
        'd-flex align-center justify-space-between': _vm.$vuetify.breakpoint.smAndDown,
        'd-flex flex-column justify-center': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"cols":"12","md":"2","sm":"4"}},[_c('div',{staticClass:"caption-text"},[_vm._v("Pool size")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.poolTier.tokensForSale,2, 0)))])]),_c('v-col',{class:{
        'd-flex align-center justify-space-between': _vm.$vuetify.breakpoint.smAndDown,
        'd-flex flex-column justify-center': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"cols":"12","md":"2","sm":"4"}},[_c('div',{staticClass:"caption-text"},[_vm._v("Max allocation")]),(!_vm.poolTier.isSaleStarted && _vm.poolTier.isCommunityRound)?_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v(" TBA ")]):_c('div',[(_vm.poolTier.isUnlimitedAllocation)?_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Unlimited")]):_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("usdCustom")(_vm.poolTier.maxCost,1, 1)))])])]),_c('v-col',{staticClass:"d-flex flex-column justify-center",class:{ 'mt-2 mb-4': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"4","sm":"8"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.poolTier.progress,2, 0))+"%")]),_c('div',{staticClass:"amount-text"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.poolTier.tokensAllocated,2, 0))+" / "+_vm._s(_vm._f("formatNumber")(_vm.poolTier.tokensForSale,2, 0))+" ")])]),_c('v-progress-linear',{staticClass:"border-radius-16 full-width",attrs:{"height":"12","rounded":"","value":_vm.poolTier.progress}})],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"2","sm":"4"}},[_c('connect-metamask',{class:{
          'fill-width': _vm.$vuetify.breakpoint.xs,
          'my-4': _vm.$vuetify.breakpoint.smAndUp,
        },attrs:{"requiredChain":_vm.poolStore.chain,"requiredChainId":_vm.poolStore.chainId,"connectText":"Connect","switchText":"Switch"}},[(_vm.poolTier.progress != 100 && !_vm.poolTier.forceFilledPool)?_c('v-btn',{staticClass:"text-none btn-text",attrs:{"depressed":"","rounded":"","color":"primary","disabled":!_vm.poolTier.canSwap,"block":_vm.$vuetify.breakpoint.xs},on:{"click":function($event){return _vm.$emit('invest')}}},[_c('span',[_vm._v("Invest")])]):_c('div',{staticClass:"d-flex align-center success--text text-body-2 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""}},[_vm._v("mdi-check")]),_vm._v(" Filled ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }