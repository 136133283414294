

























































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHover from '../../../components/card-hover.vue'
import { PoolStore } from '../stores/pool-store'
import poolState from './pool-state.vue'

@Observer
@Component({ components: { poolState, CardHover } })
export default class PoolTierItem extends Vue {
  @Prop({ required: true }) poolTier!: any
  @Prop({ required: true }) poolStore!: PoolStore

  mounted() {
    // this.model.loadDataIfNeed()
  }
}
